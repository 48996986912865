import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import YoutubeEmbed from "../../utils/YoutubeEmbed";
import useScript from "../../utils/useScript1";
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}



const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );




  return (
    <section
      {...props}
      className={outerClasses}
    >



      <div className="container-sm">
        <div id="coinmarketcap-widget-marquee" coins="1,1027,825,11419" currency="USD" theme="dark" transparent="false" show-symbol-logo="true"></div>
        <div className={innerClasses}>
                  <div className="container tiles-wrap center-content">
                    <Image
                      src={require('./../../assets/images/tgp.png')}
                      alt="TonCoin"
                      width={80}
                      height={80} 
                    />
                    <Image
                      src={require('./../../assets/images/ksp.png')}
                      alt="TonCoin"
                      width={80}
                      height={80} 
                    />
                    <Image
                      src={require('./../../assets/images/toncoin.png')}
                      alt="TonCoin"
                      width={80}
                      height={80} 
                    />
                  </div>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">Black Technology</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              Empowering you with cutting-edge technology.
              </p>

              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  {/* <Button tag="a" color="primary" wideMobile href="http://keeleyhire.com/">
                    Learn More
                  </Button> */}
                  <Button tag="a" color="primary" wideMobile href="https://t.me/Martin_Maxwell">
                    We're Hiring
                  </Button>
                </ButtonGroup>
          
            </div>
          </div>

          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
          <Image
                // className="has-shadow"
                src={require('./../../assets/images/black_bg.jpg')}
                alt="Black Technology Image"
                />
          </div>
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.youtube.com/watch?v=QGGD-kgcBms"
            videoTag="iframe" /> */}

        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;