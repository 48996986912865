import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';
import YoutubeEmbed from "../../utils/YoutubeEmbed";
import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    // title: "We're Hiring",
    // paragraph: 'Clean, Innovative and Smooth effects that are only amazing to look at.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">We're Hiring</span> - Open Positions
          </h1>
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Now Hiring
                </div>
                <h3 className="mt-0 mb-12">
                Social Media Manager - Remote
                </h3>
                <p className="m-0">
                  <ul>
                    <li>Managing our facebook, youtube, twitter & other social media outlets.</li>
                    <li>Salary $100,000/year negotiable depending on skill and experience</li>
                  </ul>
                </p>
                <Button tag="a" color="primary" wideMobile href="https://t.me/Martin_Maxwell">
                    Apply Now
                  </Button>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                  <Image
                  src={require('./../../assets/images/smm.webp')}
                  // src={require('./../../assets/images/Skunkworks_Logo.svg')}
                  alt="Social Media Manager Image"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Now Hiring
                </div>
                <h3 className="mt-0 mb-12">
                  Sales Person
                </h3>
                <p className="m-0">
                  <ul>
                    <li>Selling products and closing deals.</li>
                    <li>Salary $100,000/year negotiable depending on skill and experience</li>
                  </ul>
                </p>
                <Button tag="a" color="primary" wideMobile href="https://t.me/Martin_Maxwell">
                    Apply Now
                  </Button>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                                    <Image
                  src={require('./../../assets/images/sales.webp')}
                  // src={require('./../../assets/images/Skunkworks_Logo.svg')}
                  alt="Social Media Manager Image"
                  width={150}
                  height={150}
                />
                {/* <YoutubeEmbed embedId="QzlrgNRaYVM" /> */}
              </div>
            </div>





          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;